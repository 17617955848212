<template>
  <div class="row align-items-center">
    <!-- Profile Image -->
    <div class="col-md-4">
      <div class="d-flex justify-content-center align-items-center image-container">
        <div style='height: 350px; width: 350px; position: relative'>
          <img
            class="image"
            :src="data.photo"
            @load="onImageLoad()"
          >
        </div>
      </div>
    </div>

    <!-- Table Data -->
    <div
      class="col-md"
    >
      <table class="table mb-3">
        <tr>
          <td><strong>Nomor Karyawan</strong></td>
          <td>{{ data.employee_number_id }}</td>
        </tr>
        <tr>
          <td><strong>Nama</strong></td>
          <td>{{ data.name }}</td>
        </tr>
        <tr>
          <td><strong>Tempat Tanggal Lahir</strong></td>
          <td>{{ data.birt_place +', '+ date(data.birt_date) }}</td>
        </tr>
        <tr>
          <td><strong>Email</strong></td>
          <td>{{ data.email }}</td>
        </tr>
        <tr>
          <td><strong>Nomor Telepon</strong></td>
          <td>{{ data.mobile_phone }}</td>
        </tr>
        <tr>
          <td><strong>Alamat</strong></td>
          <td>{{ data.address }}</td>
        </tr>
        <tr>
          <td><strong>Departemen</strong></td>
          <td>{{ data.department_name }}</td>
        </tr>
        <tr>
          <td><strong>Jabatan</strong></td>
          <td>{{ data.position_name }}</td>
        </tr>
      </table>
      <button
        @click="$router.push({ path: '/human-resource/employee/edit/'+data.id })"
        class="btn mx-1 btn-success"
        v-if="manipulateBtn == true"
      ><i class="far fa-fw fa-edit"></i> Edit</button>
      <button
        @click="deleteData(data.id)"
        class="btn mx-1 btn-danger"
        v-if="manipulateBtn == true"
      ><i class="far fa-fw fa-trash-alt"></i> Hapus</button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import module from '@/core/modules/CrudModule.js'
export default {

  name: 'Detail',

  data() {
    return {
      imgLoaded: false,
      manipulateBtn:false
    }
  },

  methods: {
    date(date){
      return moment(date).format('DD-MM-YYYY')
    },
    onImageLoad() {
      this.imgLoaded = true
    },
    async deleteData(id) {
      // Delete Data
      let result = await module.delete('employees/' + id)
      // If Deleted
      if (result) {
        this.$router.push({path: '/human-resource/employee/list'})
      }
    },

    // access management
    async setActiveMenu(){

     let access_right_user = window.localStorage.getItem("access_right_display")
     let access_right = JSON.parse(access_right_user)
    
    let a
    for(a = 0; a < access_right.length; a++){
      
      
      if(access_right[a] == "7002"){
        this.manipulateBtn = true
      }
      
    }
    },

  },

  props: {
    data: Object,
  },

  mounted(){
    this.setActiveMenu()
  }

}
</script>

<style>
</style>